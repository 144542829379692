import dayjs from "dayjs";
import React from "react";

function calculateAge(dob) {
  if (!dob) {
    return "";
  }
  // Convert the input string to a date object
  let birthDate = new Date(dob);
  let today = new Date();

  // Calculate age
  let age = today.getFullYear() - birthDate.getFullYear();
  let monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the current month is before the birth month
  // or if it's the birth month but the day hasn't passed yet
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  if (age === 0) {
    return "-";
  }
  return age;
}

function changeUSDateFormat(date) {
  const formattedDate = dayjs(date).format("YYYY/MM/DD HH:mm");
  return formattedDate;
}

function splitDateTime(dateTime) {
  if (!dateTime) {
    return "";
  }
  const concatDateTime = dateTime + "Z";
  // Create a Date object from the UTC string
  const dateObj = new Date(concatDateTime);

  // Convert the date to EST/EDT (America/New_York timezone)
  const options = { timeZone: "America/New_York", hour12: true };
  const estDateObj = new Date(dateObj.toLocaleString("en-US", options));

  // Extract the date in the desired format
  const date = estDateObj.toISOString().split("T")[0]; // "2024-08-29"

  // Extract the time in the desired format with AM/PM
  let hours = estDateObj.getHours();
  const minutes = estDateObj.getMinutes().toString().padStart(2, "0");
  // const seconds = estDateObj.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12; // Convert to 12-hour format
  const time = `${hours}:${minutes} ${ampm} EST`;

  return { date, time };
}

function formatTime(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
}

function findDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const durationInMilliseconds = end - start; // Difference in milliseconds
  const durationInSeconds = Math.floor(durationInMilliseconds / 1000); // Convert to seconds
  const durationInMinutes = Math.floor(durationInSeconds / 60); // Convert to minutes
  const remainingSeconds = durationInSeconds % 60; // Get remaining seconds

  return `${durationInMinutes}:${remainingSeconds}`;
}

const getCurrentDateTime = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(now.getDate()).padStart(2, "0");

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const formatPhoneNumber = (number) => {
  // Ensure the input is numeric and remove any non-numeric characters
  const cleanNumber = number.replace(/\D/g, "");

  // Check if the number has exactly 11 digits
  if (cleanNumber.length !== 11) {
    return number;
    // throw new Error('Phone number should have exactly 11 digits.');
  }

  // Extract parts of the phone number
  const areaCode = cleanNumber.substring(1, 4); // Skip the first digit and use the next 3 for area code
  const centralOfficeCode = cleanNumber.substring(4, 7); // Next 3 digits
  const lineNumber = cleanNumber.substring(7, 11); // Last 4 digits

  // Format the phone number as (XXX) XXX-XXXX
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

const FormatArray = ({ subIndex, keyName, value }) => {
  if (
    !value ||
    value.length === 0 ||
    value === null ||
    value === undefined ||
    value === "NA" ||
    value === "na" ||
    value === "N.A" ||
    value === "n.a"
  ) {
    return (
      <li key={subIndex}>
        <strong>{keyName}:</strong> N/A
      </li>
    );
  } else if (Array.isArray(value) && value.length > 0) {
    return (
      <li key={subIndex}>
        <strong>{keyName}:</strong>{" "}
        {value.length > 0
          ? value.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 ? (
                  item
                ) : (
                  <span style={{ display: "block", paddingLeft: "2em" }}>
                    {item}
                  </span>
                )}
              </React.Fragment>
            ))
          : "N/A"}
      </li>
    );
  } else if (typeof value === "object" && value !== null) {
    return (
      <li key={subIndex}>
        <strong>{keyName}:</strong>
        <ul>
          {Object.entries(value).map(([subkey, subvalue], index) => (
            <li key={index}>
              <strong>{subkey}:</strong>{" "}
              {Array.isArray(subvalue) ? subvalue.join(", ") : String(subvalue)}
            </li>
          ))}
        </ul>
      </li>
    );
  } else if (typeof value === "string" || typeof value === "number") {
    return (
      <>
        <li key={subIndex}>
          <strong>{keyName}:</strong> {value}
        </li>
      </>
    );
  }
  return null;
};

const SubFormatArray = ({ subIndex, keyName, value }) => {
  if (
    !value ||
    value.length === 0 ||
    value === null ||
    value === undefined ||
    value === "NA" ||
    value === "na" ||
    value === "N.A" ||
    value === "n.a"
  ) {
    return (
      <li key={subIndex} style={{ marginLeft: "50px" }}>
        <strong>{keyName}:</strong> N/A
      </li>
    );
  } else if (Array.isArray(value) && value.length > 0) {
    return (
      <li key={subIndex} style={{ marginLeft: "50px" }}>
        <strong>{keyName}:</strong>{" "}
        {value.length > 0
          ? value.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 ? (
                  item
                ) : (
                  <span style={{ display: "block", paddingLeft: "2em" }}>
                    {item}
                  </span>
                )}
              </React.Fragment>
            ))
          : "N/A"}
      </li>
    );
  } else if (typeof value === "object" && value !== null) {
    return (
      <li key={subIndex} style={{ marginLeft: "50px" }}>
        <strong>{keyName}:</strong>
        <ul>
          {Object.entries(value).map(([subkey, subvalue], index) => (
            <li key={index}>
              <strong>{subkey}:</strong>{" "}
              {Array.isArray(subvalue) ? subvalue.join(", ") : String(subvalue)}
            </li>
          ))}
        </ul>
      </li>
    );
  } else if (typeof value === "string" || typeof value === "number") {
    return (
      <>
        <li key={subIndex} style={{ marginLeft: "50px" }}>
          <strong>{keyName}:</strong> {value}
        </li>
      </>
    );
  }
  return null;
};

const removeLastCommaAndWord = (str) => {
  return str.replace(/, [^,]*$/, "");
};

export {
  calculateAge,
  changeUSDateFormat,
  splitDateTime,
  formatTime,
  findDuration,
  getCurrentDateTime,
  formatPhoneNumber,
  FormatArray,
  SubFormatArray,
  removeLastCommaAndWord,
};
